#container {
  padding: 0px 15px;
  background-color: #fff;
  font-size: 14px;
  margin-top: -15px;
  padding-top: 30px;
}

#container strong {
  font-weight: 700;
}

#root {
  overflow-y: auto !important;
}
