.chips > div {
  margin-right: 10px;
  font-size: 10px;
  font-weight: bold;
  height: 26px;
}
.fleetGrid button.iconBtn {
  padding: 0px;
}
.fleetGrid button.iconBtn svg {
  font-size: 24px;
  padding: 5px;
}
.fleetGrid thead > tr,
.fleetGrid tbody > tr {
  height: auto;
}
.fleetGrid thead > tr > th {
  padding: 2px 5px !important;
  border-right: 1px solid var(--gray-100);
  font-size: 12px;
  border-top: 1px solid var(--gray-100);
  background: whitesmoke;
}
.fleetGrid thead > tr > th:first-child,
.fleetGrid tbody > tr > td:first-child {
  border-left: 1px solid var(--gray-100);
}
.fleetGrid tbody > tr > td {
  padding: 2px 5px !important;
  font-size: 12px;
}
.fleetGrid tbody td {
  border-right: 1px solid var(--gray-100);
}
.fleetGrid thead td {
  border-right: 1px solid var(--gray-100);
}
.fleetGrid .MuiToolbar-regular {
  padding: 0px;
}
.valueFilters h4 {
  margin: 0px;
  font-size: 14px;
  margin-bottom: 10px;
}
.chips {
  margin-bottom: 10px;
}
.fleetGrid .loader svg {
  color: var(--primaryColor);
}
