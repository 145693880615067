:root {
  --primaryColor: #037ff2;
  --secondaryColor: #35354a;
  --runningColor: #61a262;
  --idleColor: #ff8d00;
  --stoppedColor: #f3273b;
  --nosignalColor: #b7b7b7;
  --zoneColor: #56d9fe;
  --reportBgColor: #fff;
  --dashboardColor: #0a72bd;
  --gray-100: #ccc;
  --dimPrimary:#f2f9ff;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: var(--dimPrimary);
}

.autocomplete-box input {
  font-size: 12px !important;
}

.saveBtn {
  min-width: 130px !important;
  padding: 6px 0px !important;
}

div#root.scroll {
  overflow-y: auto;
}

a {
  color: #004f99 !important;
  text-decoration: none;
}

.primary > svg {
  color: var(--primaryColor);
}

.leftFixedMenu {
  position: fixed;
  height: 100%;
  margin: 0;
  background-color: var(--secondaryColor);
  width: 60px;
  color: #dfdeec;
  z-index: 9999999;
  font-weight: bold;
}

.leftFixedMenu > ul.topmenu {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  text-align: center;
  margin-top: 40px;
}

.leftFixedMenu > ul.bottommenu {
  position: absolute;
  bottom: 0;
  margin: 0;
  list-style: none;
  padding: 0;
  text-align: center;
  width: 100%;
}

.leftFixedMenu > ul.bottommenu > li {
  margin: 0px;
  color: #9c9c9c;
  cursor: pointer;
  height: 60px;
}

.leftFixedMenu > ul.bottommenu > li > svg {
  margin-top: 18px;
}

.leftFixedMenu > ul.bottommenu > li.active {
  color: #fff;
}

.leftFixedMenu > ul.topmenu > li {
  margin: 0px;
  cursor: pointer;
  color: #9c9c9c;
  height: 60px;
}

.leftFixedMenu > ul.topmenu > li > svg {
  margin-top: 18px;
}

.leftFixedMenu > ul.topmenu > li.active {
  color: #fff;
}

div.leftdrawer {
  position: static !important;
}
.leftdrawer-menu {
  overflow-y: auto !important;
}

.rotateScale {
  transform: rotate(320deg);
}

.leftdrawer-menu {
  left: 60px !important;
  width: 390px;
  padding: 00px;
}

.leftdrawer-menu > div {
  box-shadow: none;
}

.menucolor {
  color: #9c9c9c !important;
}

.menucolor.menuActive {
  color: #fff !important;
}

.menucolor.active {
  color: #fff !important;
}

.menucolor.hasLeftBar.active::after {
  content: "";
  position: absolute;
  right: -6px;
  border-style: solid;
  border-width: 8px 8px 8px 0px;
  border-color: transparent #ffffff transparent transparent;
}

.width100 {
  width: 100%;
}

.p0 {
  padding: 0px !important;
}

.pad5 {
  padding: 5px !important;
}

.pB5 {
  padding-bottom: 5px;
}

.mB20 {
  margin-bottom: 20px !important;
}

.mB15 {
  margin-bottom: 15px !important;
}

.mB10 {
  margin-bottom: 10px !important;
}

.mB5 {
  margin-bottom: 5px !important;
}

.mB-8 {
  margin-bottom: -8px !important;
}

.m0 {
  margin: 0px !important;
}

.mT10 {
  margin-top: 10px !important;
}
.mR15 {
  margin-right: 15px !important;
}

.menuicon {
  margin-right: 0 !important;
}

.submenu {
  margin-left: 55px !important;
}

.menu {
  overflow: hidden;
}

.tooltip {
  will-change: unset;
}

.placeholder {
  margin: 5px 0px;
  background-color: #eee;
  margin-bottom: 7px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
  height: 55px;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #35354a59;
  z-index: 999999999;
  top: 0;
}

.loader,
.loaderMsg {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff !important;
}

.loaderMsg {
  left: 25px;
  width: 100%;
  text-align: center;
  top: 58%;
  font-weight: bold;
  text-shadow: 1px 2px 5px #000;
}

.loaderMsg a {
  color: #fff !important;
}

.snackbar-btn {
  font-size: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: #fff !important;
  cursor: pointer;
  background: none;
  box-shadow: none;
  border: none;
}

.divCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.scrollBar::-webkit-scrollbar {
  width: 6px;
}

.scrollBar::-webkit-scrollbar-track {
  background: #ccc;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

.purple {
  color: var(--primaryColor) !important;
}

.w40p {
  width: 40%;
}

.w50 {
  width: 50%;
}

.no-records-found {
  color: #747474;
  text-align: center;
  height: 25px;
}

div#profileMenu ul {
  display: flex;
  flex-direction: column;
  padding: 0;
}

div#profileMenu {
  position: absolute !important;
  right: 5px;
  top: 37px !important;
  width: 135px !important;
  margin: 0 0 0 auto;
}

div#profileMenu ul > li {
  padding: 4px 15px;
  font-size: 14px;
}

#myProfileBtn button {
  border-radius: 0;
  position: relative;
  top: -1px;
  padding: 4px !important;
}

.bold {
  font-weight: 500;
}

.mB-5 {
  margin-bottom: 5px !important;
}

.mB-10 {
  margin-bottom: 10px !important;
}

.grid {
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row > div {
  margin-right: 15px;
}

.switchThumb {
  color: #eaeaea;
}

.switchRoot > span.Mui-checked .switchThumb {
  color: #6b6ade;
}

.switchRoot .switchTrack {
  background-color: #646464 !important;
}

.dropdown {
  width: 100%;
}

.label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #747474;
}

.txt {
  width: 100%;
  margin-bottom: 10px !important;
}

.txt input,
.txt textarea {
  font-size: 12px;
}

.txtfield {
  display: flex !important;
  width: 100%;
  margin-bottom: 20px !important;
}

.txtfield > label {
  font-size: 14px;
  font-weight: 500;
}

.txtfield input {
  font-size: 12px;
  padding-top: 12px;
  padding-bottom: 8px;
}

.txtfield textarea {
  font-size: 12px;
}

.txtfield > label[data-shrink="false"] {
  font-size: 12px;
}

.dataRGrid td > big {
  padding: 15px 0px;
}

.dataRGrid {
  margin-top: 10px;
  padding: 0px 5px;
}

.dataRGrid table {
  width: 100%;
  min-width: 100% !important;
}

.dataRGrid table > thead {
  background-color: #f0f0f3;
}

.dataRGrid table thead > tr > th {
  padding: 0px 3px !important;
}

.dataRGrid table thead > tr,
.dataRGrid table tbody > tr {
  height: auto;
}

.dataRGrid table tbody > tr > td {
  font-size: 12px;
  padding: 1px 4px;
}

.dataRGrid table tbody > tr > td:first-child {
  padding-left: 3px;
}

.dataRGrid thead th span {
  color: #757575;
  font-size: 14px;
  padding-top: 2px;
}

.dataRGrid thead th span > span {
  min-width: 100%;
}

.dataRGrid > div > div {
  overflow-x: hidden;
}

.dataRGrid.autoHeight > div > div {
  height: auto !important;
}
button.dataGridIcon {
  padding: 5px;
}
button.dataGridIcon svg {
  font-size: 18px;
}

label.form-label {
  font-size: 12px;
  font-weight: 500;
}

.dropdown > label {
  background: white;
  padding: 0px 5px;
  font-weight: 500;
}

.dropdown > label[data-shrink="false"] {
  font-size: 12px;
  font-weight: 500;
}

.dropdown select {
  font-size: 12px;
  padding-top: 8px;
  height: 20px;
  padding-bottom: 4px;
}

.editVehicleWrapper .col {
  width: 32%;
}

.editVehicleTab {
  min-height: auto !important;
  margin-bottom: 25px;
  margin-top: -15px;
}

.editVehicleTabIndicator {
  background: #6b6ade !important;
}

div#iconsList {
  position: absolute;
  box-shadow: 1px 2px 5px #8a8a8a;
  border-radius: 3px;
  font-size: 12px;
  width: 86%;
  background: white;
  margin-bottom: 0;
  top: 36px;
  z-index: 9999;
  left: 5px;
}

div#iconsList > ul {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 1px;
  margin-bottom: 0px;
  width: 100%;
  flex-wrap: wrap;
}

div#iconsList > ul > li > img {
  cursor: pointer;
}

div#sensorsGrid {
  overflow-x: hidden;
  margin-top: -10px;
}

div#sensorsGrid > ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

div#sensorsGrid > ul > li:first-child {
  position: relative;
  margin-right: 10px;
}

div#sensorsGrid ul > li > button {
  padding: 6px !important;
  top: 0px;
}

div#sensorsGrid ul > li svg {
  font-size: 16px;
}

input#sensorSearch {
  margin-top: 0px !important;
}

.dialogBox.editSensorWrapper {
  right: 0 !important;
  left: 0 !important;
  width: 50%;
  margin: auto;
}

.dialogBox.editSensorWrapper .col {
  width: 47%;
}

.dialogBox.editSensorWrapper .col .mB10 {
  margin-bottom: 10px !important;
}

div[role="dialog"] {
  z-index: 999999999999 !important;
}

.font-12 {
  font-size: 12px !important;
}

.profileSettingsWrapper .dialogBtn {
  display: none;
}

button.settingsBtn {
  margin-bottom: 10px;
  margin-top: 10px;
}

.settingsTabs .scrollButtons {
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}

div#myAccount {
  width: 75%;
}

.settingsGrid > ul {
  display: flex;
  list-style: none;
  padding-left: 0px;
  margin: 0;
}

.settingsGrid > ul svg {
  font-size: 16px;
}

.settingsGrid .searchDiv {
  position: relative;
  margin-top: -5px;
  margin-right: 10px;
  min-width: 250px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.fleetTooltip {
  z-index: 9999999999 !important;
}

input#assignedVehicle,
input#assignedUser {
  font-size: 12px;
  font-weight: 500;
  padding-top: 8px;
}

.autocomplete-box[aria-expanded="false"] label {
  font-size: 12px;
}

li#myProfileBtn::after {
  background: #fff;
  width: 6px;
  height: 6px;
  content: "";
  position: absolute;
  right: 12px;
  border-radius: 10px;
  top: 10px;
}

li#myProfileBtn.online::after {
  background: green;
}

li#myProfileBtn.offline::after {
  background: red;
}

.hideBtn {
  display: none !important;
}

.dialogBox.showPointWrapper {
  left: 380px !important;
  top: 50px !important;
  width: 300px;
}

.MuiSnackbar-root {
  z-index: 999999999 !important;
}

.popover-loading {
  position: absolute;
  width: 98%;
  height: 97%;
  background: #00000024;
  top: 0;
  z-index: 99999;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogBox.dateTimeWrapper {
  width: 100%;
}

div#pointIcons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.pointIconWrapper {
  width: 7.14%;
  transition: all 0.1s;
}

.pointIconWrapper img {
  cursor: pointer;
}

.pointIconWrapper.active,
.pointIconWrapper img:hover {
  filter: drop-shadow(0px 1px 3px purple);
  position: relative;
  top: -3px;
}

.miniloader {
  width: 15px !important;
  height: 15px !important;
  margin: 0 auto;
  display: block !important;
  padding: 10px 0px;
}

div#addUserVehicle .datetime-picker {
  width: 100%;
  margin-bottom: 15px;
}

div#addUserVehicle .datetime-picker input {
  font-size: 12px;
}

div#addUserVehicle .datetime-picker button {
  padding: 8px;
}

div#addUserVehicle .datetime-picker button svg,
div#addSubAccount .datetime-picker button svg,
div#shareVehicleDialog .datetime-picker button svg {
  font-size: 18px;
}

div#addSubAccount .datetime-picker input,
div#shareVehicleDialog .datetime-picker input {
  font-size: 12px;
}

div#addSubAccount .datetime-picker label,
div#shareVehicleDialog .datetime-picker label {
  font-size: 16px;
  font-weight: 500;
}

div#addSubAccount .datetime-picker {
  width: 100%;
  margin-bottom: 15px;
}

div#addSubAccount .dialogBody.zonePopupBody {
  min-width: 600px;
}

div#addSubAccount .miniloader {
  margin-top: 15px;
}

.w33 {
  width: 33.33%;
}

.w45 {
  width: 45%;
}

.w100 {
  width: 100%;
}

.multiSelectBox select {
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  padding-right: 15px !important;
}

.multiSelectBox select option {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
}

.multiSelectBox > div {
  width: 95%;
}

.multiSelectBox label {
  font-weight: 500;
}

.multiSelectBox .MuiInput-underline:after,
.multiSelectBox .MuiInput-underline::before {
  border: none;
}

div#iconsList > ul > li {
  padding: 2px 4px;
}

div#iconsList img {
  width: 24px;
}

div#iconsList img.active,
div#iconsList img:hover {
  box-shadow: 1px 1px 5px #a6a6a6;
  border-radius: 4px;
}

div#vIconSelector {
  position: relative;
  margin-bottom: 18px;
}

div#vIconSelector button {
  border: 1px solid #ddd;
  margin-left: 5px;
  padding: 4px 0px;
}

div#iconsList:before {
  position: absolute;
  top: -8px;
  left: 18%;
  content: "";
  z-index: 9999999999;
  border-bottom: 10px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: none;
  filter: drop-shadow(0px -2px 1px #c6c6c6);
}

.leaflet-popup-content-wrapper {
  border-radius: 4px !important;
}

.dialogBox.profileSettingsWrapper .dialogTitle {
  margin-bottom: 0px !important;
}

ul#gridFilters {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

ul#gridFilters > li {
  position: relative;
}

ul#gridFilters > li > button {
  padding: 8px;
}

ul#gridFilters button svg {
  font-size: 16px;
}

ul#gridFilters .SearchInput {
  margin-top: 2px !important;
  width: 250px !important;
}

ul#gridFilters > li > button.searchBtn {
  top: 0px;
}

div#addRoutePopup .grid {
  padding-right: 10px;
}

input#routeStartStop,
input#routeEndStop {
  font-size: 12px;
  top: 2px;
  position: relative;
}

div.leftdrawer > div {
  overflow: hidden;
}

input#tailColor {
  color: #fff;
  font-weight: 500;
  text-shadow: 0px 1px 5px #000;
  text-transform: uppercase;
}

.radioGroupInline {
  padding-left: 2px !important;
}

.radioGroupInline legend {
  font-size: 12px;
  font-weight: 500;
  margin-left: -1px;
  color: #0000008f !important;
}

.radioGroupInline div[role="radiogroup"] {
  display: flex;
  flex-direction: initial;
  width: 100%;
}

.radioGroupInline label > span {
  font-size: 12px;
}

.radioGroupInline .MuiIconButton-label {
  color: #6b6ade;
}

.radioGroupInline label svg {
  font-size: 12px;
}

div#editVehicle input#vehicleRoute {
  font-size: 12px;
}

div#editVehicle .autocomplete-box {
  margin-top: 0px !important;
}

div#editVehicle .label {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.width75 {
  width: 75%;
}

.width60 {
  width: 60%;
}

.width50 {
  width: 50%;
}

.width48 {
  width: 48%;
}

div#sensorCalibrations {
  width: 100%;
  height: auto !important;
  border: 1px solid #dddddd;
  margin-bottom: 15px;
}

div#sensorCalibrations > div > div {
  height: 200px !important;
}

#sensorCalibAddWrap {
  display: flex;
  align-items: center;
}

div#sensorCalibAddWrap > button {
  padding: 5px;
}

div#sensorCalibAddWrap svg {
  font-size: 18px;
}

div#sensorCalibAddWrap > div {
  margin-bottom: 0 !important;
  margin-right: 15px;
}

.singleDatePickerWrapper > div {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.singleDatePickerWrapper input {
  font-size: 12px;
}

.singleDatePickerWrapper button {
  padding: 8px;
}

.singleDatePickerWrapper svg {
  font-size: 18px;
}

.fleetTabs {
  min-height: auto !important;
}

.fleetTabs .fleettab.activeTab {
  background: #fff;
  color: #353549 !important;
}

.fleetTabs .fleettab {
  padding: 5px !important;
  min-height: auto;
  min-width: 130px;
  font-size: 12px;
  border-radius: 3px;
}

.fleetTabIndicator {
  background: var(--primaryColor) !important;
}

.textOneLine {
  white-space: nowrap;
}

div[role="tooltip"] {
  z-index: 99999999;
}

div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus.running {
  background-color: var(--runningColor);
}
div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus.idle {
  background-color: var(--idleColor);
}
div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus.stopped {
  background-color: var(--stoppedColor);
}
div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus.nosignal {
  background-color: var(--nosignalColor);
}

.radioBtn span,
.checkBox span {
  font-size: 12px;
}
.radioBtn svg,
.checkBox svg {
  font-size: 19px;
  color: var(--primaryColor);
}
.radioBtn svg {
  font-size: 18px;
}

.formGroup {
  margin-bottom: 12px;
}
.formGroup > label {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.txtbox {
  width: 100%;
}
.txtbox input {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  font-size: 12px;
  outline: none;
  min-height: 20px;
}
.txtbox textarea {
  font-size: 14px;
  outline: none;
}
.txtbox input[disabled] {
  background: #efefef;
}
input#gSearchPlace {
  width: 98%;
  min-height: 26px;
  padding-left: 6px;
  border-radius: 4px;
  border: 1px solid #c9c9c9;
  outline: none;
}
label.formLabel {
  font-size: 14px;
  font-weight: 500;
}
button.primaryBtn {
  background: var(--primaryColor);
  color: #fff;
  padding: 5px 45px;
}
button.primaryBtn:hover {
  background: var(--primaryColor);
}
.width45 {
  width: 45%;
}
.autocomplete-dropdown-container {
  max-width: 100%;
}
button.addBtn {
  background-color: #727272;
}
button.maptab {
  font-size: 12px !important;
}
span.mapTabIndicator {
  background: var(--primaryColor) !important;
  bottom: 8px;
}
.mapTabs {
  width: 92% !important;
  margin: 0px auto !important;
}
.autocomplete-box input {
  font-size: 14px;
}
.autocompletePopper {
  /* border: 1px solid #c6c6c6; */
  border-top: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.autocompletePopper div {
  font-size: 12px !important;
}
button.defaultBtn {
  font-size: 12px;
  background: #dbdbdd;
  margin-right: 15px;
}
button.defaultBtn:hover {
  background: #dbdbdd;
}
input#trackFdt,
input#trackTdt {
  min-height: 30px;
  border: 1px solid #ccc;
  box-shadow: none;
  outline: none;
  border-radius: 4px;
  padding: 0px 5px;
  width: 100%;
}

.markerPopup .editBtn {
  padding: 4px;
  position: relative;
  top: -4px;
}
.markerPopup .editBtn svg {
  font-size: 14px;
}
.chkBox > span {
  color: var(--primaryColor);
}
#updateLatLng {
  font-size: 14px !important;
  position: relative;
  top: 1px;
}
#placeSearchWrapper {
  position: fixed;
  top: 20px;
  left: 38%;
  right: 0;
  display: flex;
  background: #fff;
  width: 42%;
  z-index: 99999;
  border-radius: 5px;
}

button.iconBtn {
  padding: 5px;
  margin-left: 10px;
}
button.iconBtn svg {
  font-size: 17px;
}
h4.frmGroupTitle {
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.loadingGif {
  display: flex;
  justify-content: center;
  height: 100px !important;
  align-items: center;
}
.loadingGif > div {
  width: 20px !important;
  height: 20px !important;
}
.loadingGif svg {
  color: var(--primaryColor);
}
.popupDialog {
  width: auto !important;
}
.popupDialog .dialogBody.popupDialogBody {
  width: 500px;
  position: relative;
  left: 10%;
}

.tabContent {
  padding: 15px 0px;
}
.tabContent .dataRGrid {
  width: 95%;
}
.autocomplete-box input[disabled] {
  font-weight: bold;
  color: #747474 !important;
}
.datetime-picker input {
  font-size: 12px;
}
.datetime-picker button {
  position: relative;
  right: -15px;
}
.datetime-picker svg {
  font-size: 20px;
}
.gridBtns {
  display: flex;
  margin-bottom: 10px;
  margin-top: 12px;
  align-items: center;
}
.gridBtns > div {
  margin-right: 15px;
}
.gridBtns button svg {
  font-size: 18px;
}
span.btnTxt {
  position: relative;
  top: 1px;
  left: -4px;
  font-weight: bold;
}

button.statusBtn {
  background: #ccc;
  margin-right: 15px;
  font-size: 12px;
  text-transform: capitalize;
  padding: 3px 10px;
  color: #000;
}
button.statusBtn.active {
  background: var(--primaryColor);
  color: #fff;
}
.timePicker input {
  font-size: 12px;
  position: relative;
  top: 3px;
}
.timePicker {
  position: relative;
  top: -5px;
}
span#client-snackbar {
  font-weight: bold;
}
.datetime-picker input[disabled] {
  font-weight: bold;
}
.dataGridIcon.green {
  color: #01b501;
}
.dataGridIcon.red {
  color: red;
}
