#container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subContainer {
  background-color: white;
  padding: 25px 15px;
  box-shadow: 0px 3px 15px #00000047;
  width: 350px;
  border-radius: 10px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
}
.subContainer > div {
  margin-bottom: 10px;
}
p.titleText {
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 600;
}
.txtBox {
  margin-bottom: 15px;
}
.checkboxClr {
  color: #2196f3 !important;
}
button#loginBtn {
  background-color: #2196f3;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #fff;
}
img.logo {
  margin: 15px 0px;
}
