div#adminDashboard {
  padding-left: 50px;
}
div#dashTopbar {
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  margin-bottom: 50px;
  background: #fff;
  box-shadow: 1px 2px 20px #b9b9b9;
  padding: 0px 1%;
}
#dashTopbar > img.appLogo {
  width: 100px;
  padding: 10px 10px;
}
div#dashTopbar > h4 {
  font-size: 24px;
  margin: 0px;
  font-weight: 500;
}
.dashMenus {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  margin: auto;
  margin-top: 30px;
}
.dashMenuWrapper {
  width: 20%;
  margin-bottom: 50px;
}
.dashMenu {
  background: #fff;
  width: 85%;
  margin: auto;
  min-height: 130px;
  box-shadow: 1px 2px 10px #d5d5d5;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}
div#dashDialog {
  width: 100%;
}
.dashMenuTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  width: 100%;
}
.dashMenuTextWrapper > h4 {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
  text-align: center;
  padding: 0px 5px;
}
.dashMenuTextWrapper > svg {
  margin-right: 10px;
}
.dashMenuCount {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashMenuCount > h4 {
  font-size: 40px;
  margin: 0px;
  color: var(--primaryColor);
}
.dashMenuIcon {
  width: 40%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashMenuDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #007ff2;
  color: #fff;
}
.dashMenuIcon > img {
  color: var(--primaryColor);
  font-size: 35px;
  /* background: #f5f5f5; */
  padding: 10px;
  /* border-radius: 50%; */
  width: 45%;
}
.selectBtn {
  margin-bottom: 10px;
}
.selectBtn button {
  background: #dfdfdf;
}